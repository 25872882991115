import React from 'react'
import { MenuType } from './plugins/menu'
import { Events } from '../home/plugins/action_title'

interface Context {
    appConfig?: any
    detailData?: any //裁片数据
    curCanvasClient?: any //当前canvas句柄
    curThreeClient?: any //当前three句柄
    controPosition?: any //控制icon位置信息
    editMode?: MenuType //当前编辑模式
    onEditMode?: any //更新当前更新模式
    fabricW?: number //fabric宽度
    curCutpartCode?: string //当前选中的code
    fabricClient?: any //fabric句柄
    threeClient?: any,//three句柄
    titleHeight?: number, //title高度
    menuHeight?: number //菜单高度
    curActivityObject?: any //当前活动对象
    onSubMenuMode?: any //子菜单点击
    subMenuMode?: number //使用子菜单功能
    showTitle?: boolean //显示title
    setShowTitle?: (s: boolean) => void; //控制显示title
    safeHeigth?: number //安全高度
    objectModified?: number //fabric内canvas对象改变
    preview3D?: () => void;//预览3D
    curFabric?: any //布料信息
    ossClient?: any //oss句柄
    setCurEvent?: (s: Events) => void//设置当前事件
    curEvent?: Events //当前事件
    titleChange?: any //选择部位回调
}

export default React.createContext<Context>({})