import { useContext, useState, useImperativeHandle, useEffect } from 'react'
import OpenCutpartIcon from '../../../../static/img/open_cutpart_icon.png'
import TContext from '../../context'
import PContext from '../context'
import { Popup, Button, Toast } from '@nutui/nutui-react';
import http from '../../../../lib/http'
import * as apis from '../../../../lib/apis'
import md5 from 'md5'
import OSS from 'ali-oss'
import { Menu, MenuItem } from '@nutui/nutui-react';

export const Height = 44
export const Bottom = 10

interface Props {
    action?: any
}

export interface Action {
    setShowPrice?: (isShow: boolean) => void;
    setShowSubmitOrder?: (isShow: boolean) => void;
    confirm?: () => boolean;
    cancel?: () => void;
}

const Index = (props: Props) => {
    const [showBasic, setShowBasic] = useState(false);
    const [showSubmitOrder, setShowSubmitOrder] = useState(false);
    const [loadingTxt, setLoadingTxt] = useState<string>("提交订单")
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    const openCutpart = () => {
        pc.openCutpart!()
    }

    const setShowPrice = (isShow: boolean) => {
        setShowBasic(isShow)
    }

    useImperativeHandle(props.action, () => ({
        setShowPrice,
        setShowSubmitOrder
    }));



    //计算价钱
    const cPrice = (): any => {
        let basePrice = parseFloat((tc?.detailData?.price / 100).toFixed(2))
        let frushPrice = 0
        let cutArr = []
        if (pc?.notEmptyCodes) {
            for (let i = 0; i < pc?.notEmptyCodes?.length; i++) {
                let code = pc?.notEmptyCodes[i]
                let f = tc?.detailData?.materials?.cutparts?.find((v: any) => v.code === code)
                if (f) {
                    basePrice += f.price
                    frushPrice += f.price
                    cutArr.push(f)
                }
            }
        }
        return [<span>¥ {basePrice}</span>, cutArr, frushPrice, basePrice]
    }

    const sleep = (t: number) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(null)
            }, t)
        })
    }

    //生成并上传效果图
    const makeAndUploadImage = async (onProgress: any) => {
        //停止3D与2D的更新
        tc?.threeClient?.stopNeedsUpdate()
        const data: any = { effect_img: [], cutpart_img: [], cutpart_effect_img: [] }
        for (let i = 0; i < tc?.detailData?.materials?.cutparts?.length; i++) {
            let item = tc?.detailData?.materials?.cutparts[i]
            if (item.position) {
                tc?.threeClient?.setModelPosition(item.position)
            }
            await sleep(300)
            //获取blob
            const blob = await tc?.threeClient?.screePhoto()
            //上传效果图
            const uuid = await http.get(apis.UUID)
            const filename = `files/${md5(uuid)}.png`;
            const ossData = await tc?.ossClient?.put(filename, blob)
            if (!ossData.name) {
                Toast.fail("资源文件上传失败")
                return
            }
            data.effect_img.push({
                code: item.code,
                img: ossData.name
            })

            //上传版片效果图
            const cutpartBlob3 = await tc?.fabricClient?.toBlob(item.code, tc?.fabricW, process.env.REACT_APP_OSS_URL + item.img)
            const uuid3 = await http.get(apis.UUID)
            const filename3 = `files/${md5(uuid3)}.png`;
            const ossData3 = await tc?.ossClient?.put(filename3, cutpartBlob3)
            if (!ossData3.name) {
                Toast.fail("资源文件上传失败")
                return
            }
            data.cutpart_effect_img.push({
                code: item.code,
                img: ossData3.name
            })

            //上传裁片图
            const cutpartBlob = await tc?.fabricClient?.toBlob(item.code, tc?.fabricW, "")
            const uuid2 = await http.get(apis.UUID)
            const filename2 = `files/${md5(uuid2)}.png`;
            const ossData2 = await tc?.ossClient?.put(filename2, cutpartBlob)
            if (!ossData2.name) {
                Toast.fail("资源文件上传失败")
                return
            }
            data.cutpart_img.push({
                code: item.code,
                img: ossData2.name
            })

            onProgress(i / (tc?.detailData?.materials?.cutparts?.length - 1) * 100)
        }
        return data
    }

    //提交订单
    const submitOrder = async () => {
        if (tc?.curEvent) { //如果当前有事件未完成，不可提交订单
            Toast.warn("请先完成当前的编辑")
            return
        }

        Toast.loading("提交中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })

        try {
            const mdata = await makeAndUploadImage((p: any) => {
                setLoadingTxt(p.toFixed(2) + "%")
            })

            //构造提交数据
            const postData: any = {}
            const resourceData: any = {}
            postData.hash_id = tc?.detailData?.hash_id
            postData.price_str = cPrice()[3].toFixed(2)
            if (pc?.notEmptyCodes && pc?.notEmptyCodes?.length !== 0) {
                postData.printing_code = JSON.stringify(pc?.notEmptyCodes)
            }
            if (tc?.curFabric?.color) {
                postData.color = tc?.curFabric?.color
            }
            resourceData.canvas_width = tc?.fabricW as number
            resourceData.canvas_data = tc?.fabricClient?.allToJson()
            for (let i = 0; i < resourceData.canvas_data.length; i++) {
                let item = resourceData.canvas_data[i]
                let f = tc?.detailData?.materials?.cutparts?.find((j: any) => j.code === item.code)
                if (f) {
                    item.name = f.txt
                }
            }

            resourceData.model_url = process.env.REACT_APP_OSS_URL + tc?.detailData?.model_url
            resourceData.map_info = tc?.curFabric
            resourceData.materials = mdata

            const uuid = await http.get(apis.UUID)
            const filename = `files/${md5(uuid)}.json`;
            const ossData = await tc?.ossClient?.put(filename, OSS.Buffer(JSON.stringify(resourceData)))
            if (!ossData.name) {
                Toast.fail("提交失败,请重试")
                Toast.hide()
                return
            }
            try {
                postData.cover = mdata.effect_img[0].img
            } catch (error) {

            }
            postData.resource_url = ossData.name
            postData.materials = JSON.stringify(tc?.detailData?.materials)
            postData.extra = window.location.href.split("?")[1] ? window.location.href.split("?")[1] : ""
            postData.h5_host = window.location.origin
            let orderNo = await http.post(apis.ORDER_CREATE, postData)
            Toast.success("提交成功")
            setTimeout(() => {
                //window.location.reload()
                window.location.replace(`/success/${tc?.detailData?.hash_id}/${orderNo}`)
                //跳转到第三方
            }, 300)
            setShowBasic(false)
            setLoadingTxt("提交订单")
        } catch (error) {
            console.log(error)
            Toast.fail("提交失败,请重试")
            setLoadingTxt("提交订单")
        }
        Toast.hide()

    }

    const optionDataHandle = () => {
        const arr = []
        for (let i = 0; i < tc.detailData?.product_materials?.cutparts?.length; i++) {
            let item = tc.detailData.product_materials.cutparts[i]
            arr.push({ text: item.txt, value: item.code })
        }
        if (!tc.curCutpartCode) {
            return []
        }
        return arr
    }



    const menuChange = (val: any) => {
        tc?.titleChange(val.value)
    }

    return (
        <div style={{
            position: "fixed",
            bottom: (tc?.menuHeight as number + Bottom) || 0,
            width: "100%",
            left: 0,
            right: 0,
            margin: "auto",
            height: Height
        }}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 20px"
            }}>
                <div>
                    <div onClick={() => {
                        setShowBasic(true);
                        setShowSubmitOrder(false)
                    }} style={{
                        color: "#F57766"
                    }}><span style={{
                        fontWeight: 600,
                        fontSize: 14
                    }}>{cPrice()[0]}</span> <span style={{
                        fontSize: 12
                    }}>查看明细</span></div>
                    <div style={{
                        fontSize: 12,
                        color: "#333333",
                        marginTop: 4,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: 220
                    }}>款式：{tc?.detailData?.name}</div>
                </div>
                <div style={{
                    width: 120,
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Menu style={{ width: 90, height: 28 }}>
                        <MenuItem style={{ height: "100%" }} onChange={menuChange} options={optionDataHandle()} value={tc?.curCutpartCode} direction="up" />
                    </Menu>
                    <div style={{
                        backgroundColor: "#f9f9f9",
                        width: 34,
                        height: 34,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                        flexShrink: 0
                    }}>
                        <a style={{ color: "rgb(51, 51, 51)" }} onClick={openCutpart}>编辑</a>
                        {/* <img style={{
                            width: "85%",
                            height: "85%",
                            display: "block",
                            borderRadius: "50%"
                        }} onClick={openCutpart} src={OpenCutpartIcon} alt="" /> */}
                    </div>
                </div>
            </div>
            <Popup
                closeIconPosition="top-left"
                visible={showBasic}
                teleport={document.body}
                overlayStyle={{ zIndex: 13 }}
                closeable
                style={{ height: '50vh' }}
                position="bottom"
                onClose={() => {
                    // console.log(loadingTxt, loadingTxt !== "提交订单")
                    // if (loadingTxt !== "提交订单") {
                    //     return
                    // }
                    setShowBasic(false)
                }}
            >
                <div>
                    <div style={{
                        fontSize: 14,
                        fontWeight: 500,
                        height: 45,
                        lineHeight: "45px",
                        textAlign: "center"
                    }}>价格明细</div>
                    <div style={{
                        overflow: "auto",
                        height: "calc(50vh - 100px)"
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px"
                        }}>
                            <span style={{
                                color: "#696969",
                                fontSize: 16
                            }}>商品单价</span>
                            <span style={{
                                color: "#333333",
                                fontSize: 16
                            }}>¥ {(tc?.detailData?.price / 100)?.toFixed(2)?.split(".")[0]}<span style={{
                                color: "#333333",
                                fontSize: 14
                            }}>.{(tc?.detailData?.price / 100)?.toFixed(2)?.split(".")[1]}</span></span>
                        </div>
                        <div style={{ height: 12 }}></div>
                        {
                            cPrice()[1]?.map((v: any) => (
                                <div key={v.code} style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "0 20px",
                                    marginBottom: 12
                                }}>
                                    <span style={{
                                        color: "#696969",
                                        fontSize: 16
                                    }}>{v.txt}印刷</span>
                                    <span style={{
                                        color: "#333333",
                                        fontSize: 16
                                    }}>¥ {(v.price)?.toFixed(2)?.split(".")[0]}<span style={{
                                        color: "#333333",
                                        fontSize: 14
                                    }}>.{(v.price)?.toFixed(2)?.split(".")[1]}</span></span>
                                </div>
                            ))
                        }
                        {
                            cPrice()[2] !== 0 && <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 20px",
                                marginBottom: 12
                            }}>
                                <span style={{
                                    color: "#696969",
                                    fontSize: 16
                                }}>印刷总价</span>
                                <span style={{
                                    color: "#333333",
                                    fontSize: 16
                                }}>¥ {(cPrice()[2])?.toFixed(2)?.split(".")[0]}<span style={{
                                    color: "#333333",
                                    fontSize: 14
                                }}>.{(cPrice()[2])?.toFixed(2)?.split(".")[1]}</span></span>
                            </div>
                        }
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px"
                        }}>
                            <span style={{
                                color: "#f37972",
                                fontSize: 16
                            }}>总价</span>
                            <span style={{
                                color: "#f37972",
                                fontSize: 16
                            }}>¥ {cPrice()[3]?.toFixed(2)?.split(".")[0]}<span style={{
                                color: "#f37972",
                                fontSize: 14
                            }}>.{cPrice()[3]?.toFixed(2)?.split(".")[1]}</span></span>
                        </div>

                    </div>
                    {
                        showSubmitOrder && <div style={{ textAlign: "center" }}>
                            <Button loading={loadingTxt !== "提交订单"} onClick={submitOrder} style={{ background: "rgb(245, 119, 102)" }} type="primary">{loadingTxt}</Button>
                        </div>
                    }
                </div>
            </Popup>
        </div>
    )
}

export default Index