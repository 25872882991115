import React, { useState, useImperativeHandle, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import TContext from '../../context'
import PContext from '../context'
import Animate from '../../../animate'
import { Toast, Empty, Infiniteloading } from '@nutui/nutui-react';
import http from '../../../../lib/http'
import * as apis from '../../../../lib/apis'
import { cloneDeep } from 'lodash';

interface Props {
    action?: any
}

export interface Action {
    setShow?: (isShow: boolean) => void;
    confirm?: () => boolean;
    cancel?: () => void;
}

const groupHeight = 40
const titleHeight = 30
export const Height = groupHeight

var previewPatternObject: any = null

const Index = (props: Props) => {
    const params: any = useParams()
    const [show, setInnerShow] = useState(false)
    const [page, setPage] = useState(1)
    const [patternGroups, setPatternGroups] = useState<any>([])
    const [patternList, setPatternList] = useState({ list: [], total: 0 })
    const [currentPatternId, setCurrentPatternId] = useState(-1)
    const [curPatternGroupId, setCurPatternGroupId] = useState<number>(0)
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    useImperativeHandle(props.action, () => ({
        setShow,
        confirm,
        cancel
    }));

    const loadPatternList = async (categoryId: number, page: number) => {
        try {
            const patterns: any = await http.post(apis.PATTERN_LIST, {
                page: page,
                page_size: 10,
                status: 1,
                category_id: categoryId,
                hash_id: params.id
            })
            if (page === 1) {
                setPatternList(patterns)
                return
            }
            let cpList: any = cloneDeep(patternList)
            cpList.total = patterns.total
            cpList.list = [...cpList.list, ...patterns.list]
            setPatternList(cpList)
        } catch (error) {

        }
    }

    const setShow = async (isShow: boolean) => {
        previewPatternObject = null
        setInnerShow(isShow)
        if (isShow) {
            Toast.loading("加载中", {
                cover: true, // 是否展示透明遮罩层
                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                closeOnClickOverlay: false, // 点击遮罩可关闭
                duration: 0
            })
            setPage(1)
            try {
                const patternGroups: any = await http.post(apis.PATTERN_CATEGORY_LIST, {
                    page: 1,
                    page_size: 100,
                    status: 1,
                    hash_id: params.id
                })
                patternGroups?.list?.unshift({ id: 0, name: "全部" })
                setPatternGroups(patternGroups?.list)
                await loadPatternList(0, 1)
                setCurPatternGroupId(0)
            } catch (error) {

            }
            Toast.hide()
        }
    }

    const loadPattern = async (groupId: number) => {
        if (groupId === curPatternGroupId) {
            return
        }
        setPage(1)
        await loadPatternList(groupId, 1)
        setCurPatternGroupId(groupId)
    }

    //纹样预览
    const previewPattern = async (v: any) => {
        Toast.loading("图片加载中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        try {
            setCurrentPatternId(v.id)
            if (previewPatternObject) {
                tc.curCanvasClient.getFabricCanvas().remove(previewPatternObject)
                previewPatternObject = null
            } else {
                pc?.addStep!(null)
            }
            previewPatternObject = await tc.curCanvasClient.addImageById(process.env.REACT_APP_OSS_URL + v.path, tc.fabricW as number * 0.5)
            tc.curCanvasClient.getFabricCanvas().renderAll()
            Toast.hide()
        } catch (error) {
            Toast.fail("失败，请重试一下")
        }
    }

    // //取消
    const cancel = () => {
        setInnerShow(false)
        setCurrentPatternId(-1)
    }

    //确认
    const confirm = () => {
        if (!previewPatternObject) {
            return false
        }
        setInnerShow(false)
        setCurrentPatternId(-1)
        tc.curCanvasClient.getFabricCanvas().setActiveObject(previewPatternObject)
        tc.curCanvasClient.getFabricCanvas().renderAll()
        return true
    }

    //加载更多
    const onLoadMore = async (done: () => void) => {
        let cpPage = cloneDeep(page)
        cpPage++
        setPage(cpPage)
        try {
            await loadPatternList(curPatternGroupId, cpPage)
        } catch (error) {
            let cpPage = cloneDeep(page)
            cpPage--
            setPage(cpPage)
        }
        done()
    }


    return (

        <Animate show={show} style={{
            // display: show ? 'block' : 'none',
            bottom: 0,
            position: "fixed",
            left: 0,
            zIndex: 11,
            width: "100%",
            background: "#FFFFFF",
            paddingBottom: tc.safeHeigth
        }}>
            <div style={{ height: groupHeight, background: "#FFFFFF", display: "flex", justifyContent: "left", alignItems: "center", padding: "0 5px" }}>
                <Swiper
                    slidesPerView="auto"
                    style={{
                        overflow: 'hidden'
                    }}
                >
                    {
                        patternGroups.map((v: any, i: number) => (
                            <SwiperSlide
                                key={v.id}
                                style={{
                                    width: 'auto'
                                }}
                                onClick={() => loadPattern(v.id)}
                            >
                                <div style={{ padding: '0 12px', color: v.id === curPatternGroupId ? '#333333' : '#999999', display: 'inline-block', height: titleHeight, lineHeight: titleHeight + 'px', textAlign: 'center', fontWeight: 500, position: 'relative', fontSize: 14 }}>
                                    <span>{v.name}</span>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div id="scroll" style={{
                height: tc.fabricW || 0,
                overflowY: "auto",
                overflowX: "hidden",
                padding: "0 5px"
            }}>
                {
                    patternList?.list && patternList?.list?.length !== 0 ? <div style={{
                        width: "calc(100vw - 13px)",
                        overflowX: "hidden"
                    }}>
                        <Infiniteloading
                            loadIcon=""
                            containerId="scroll"
                            useWindow={false}
                            hasMore={patternList?.total !== patternList?.list?.length}
                            onLoadMore={onLoadMore}
                            style={{
                                paddingBottom: 12
                            }}
                        >
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(4,auto)"
                            }}>
                                {
                                    patternList?.list?.map((v: any, i) => (
                                        <div key={v.id} onClick={() => previewPattern(v)} style={{
                                            width: "calc((100vw - 13px)/4.4)",
                                            height: "calc((100vw - 13px)/4.4)",
                                            border: currentPatternId === v.id ? "0.3vw solid #F57766" : "0.3vw solid #EEEEED",
                                            overflow: "hidden",
                                            borderRadius: 8,
                                            margin: "0.8vw"
                                        }}>
                                            <img style={{ width: '100%', height: '100%' }} src={process.env.REACT_APP_OSS_URL + v.path + "?x-oss-process=image/resize,w_300"} alt="" />
                                        </div>
                                    ))
                                }
                            </div>
                        </Infiniteloading>
                    </div> : <div style={{
                        width: "calc(100vw - 13px)",
                        overflowX: "hidden"
                    }}>
                        <Empty imageSize={Number(tc.fabricW) * 0.5 || 0} description="无数据" />
                    </div>
                }

            </div>
        </Animate>

    )

}

export default Index