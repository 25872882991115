import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Client3D from "../../lib/three2";
import ClientFabric from "../../lib/fabric"
import http from '../../lib/http'
import fontLoader from '../../lib/font'
import { maps } from '../../lib/definit'
import * as apis from '../../lib/apis'
import Title, { Action as TitleAction } from './plugins/title'
import Menu, { Action as MenuAction } from './plugins/menu'
import { Height as HeadHeight } from './plugins/head'
import { Height as InfoHeight, Bottom as InfoBottom } from './plugins/info'
import Loading from "./plugins/loading";
import Plugins from './plugins'
import Context from './context'
import { Height as CutpartHeight } from './plugins/cutpart'
import { Button, Toast, Drag, FixedNav } from '@nutui/nutui-react';
import OSS from 'ali-oss'
import { Events } from '../home/plugins/action_title'
import { sleep } from "../../common/tool";
import axios from "axios";

const fclients = new Map()
const threeclients = new Map()

var temp3DheightCache: any = {}
var needHideTitle = false

const Index = (props: any) => {
    const params: any = useParams()
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [detailData, setDetailData] = useState<any>({ materials: { cutparts: [] } })
    const [curCutpartCode, setCurCutpartCode] = useState("")
    const [curCanvasClient, setCurCanvasClient] = useState<any>()
    const [curThreeClient, setCurThreeClient] = useState<any>()
    const [fabricClient, setFabricClient] = useState<any>(null) //fabric句柄
    const [threeClient, setThreeClient] = useState<Client3D>() //3D句柄
    const [controPosition, setControPosition] = useState<any>()
    const [editMode, setEditMode] = useState<any>()
    const [fabricW, setFabricW] = useState<number>()
    const [menuHeight, setMenuHeight] = useState<number>()
    const [curActivityObject, setCurActivityObject] = useState<any>()
    const [subMenuMode, onSubMenuMode] = useState<number>()
    const [showTitle, setShowTitle] = useState<boolean>(true)
    const [safeHeigth, setSafeHeigth] = useState<number>(-1)
    const [progress, setProgress] = useState<number>(0)
    const [objectModified, setObjectModified] = useState(0)
    const [previewMode, setPreViewMode] = useState<boolean>(false)
    const [visibleFixedNav, setVisibleFixedNav] = useState(false);
    const [curFabric, setCurFabric] = useState<any>({})
    const [action3D, setAction3D] = useState<any>({})
    const [ossClient, setOssClient] = useState({})
    const [curEvent, setCurEvent] = useState<Events>()

    const [appConfig, setAppConfig] = useState<any>({})



    const safeHeightRef: any = useRef()
    const menuRef: React.MutableRefObject<MenuAction | undefined> = useRef()

    const threeClientRef: any = useRef()


    //当code改变时，失去焦点
    useEffect(() => {
        curCanvasClient?.discardActiveObject()
        threeclients.get(curCutpartCode)?.activate()
    }, [curCutpartCode, curCanvasClient])


    //title滑动
    const titleChange = async (code: string, d = detailData) => {
        const fclient = fclients.get(code)
        if (!fclient) return
        const tclient = threeclients.get(code)
        if (!tclient) return

        let f = d?.materials?.cutparts?.find((i: any) => i.code === code)
        setCurCutpartCode(code)
        setCurCanvasClient(fclient)
        setCurThreeClient(tclient)
        if (f.position) {
            threeClientRef?.current?.setModelPosition(f.position)
        }
        // console.log(fclients)
        // await fclient.addImageById("https://file-1254182580.cos.ap-beijing.myqcloud.com/2021-12-10/1639141117721.png",300)
        // tclient.needsUpdate(500)
        return true
    }

    //更新当前更新模式
    const onEditMode = (mode: string) => {
        setEditMode(mode)
    }

    //预览3D
    const preview3D = () => {
        temp3DheightCache.height = threeClient?.getCanvasDom()?.clientHeight
        if (showTitle) {
            needHideTitle = true
            setShowTitle(false)
        }
        threeClient?.setCanvasSize(document.body.clientWidth, document.body.clientHeight)
        setPreViewMode(true)
    }

    //退出预览3D
    const quitPreview3D = () => {
        if (needHideTitle) {
            setShowTitle(true)
        }
        threeClient?.setCanvasSize(document.body.clientWidth, temp3DheightCache.height)
        needHideTitle = false
        setPreViewMode(false)
    }

    //面料切换
    const fixedNavOnChange = (e: any) => {
        setVisibleFixedNav(e)
    }

    //选择面料
    const choiceFabric = async (v: any, a: any, b: any) => {
        if (v.color === curFabric.color) {
            return
        }
        try {
            let cli: any
            if (a) {
                cli = a
            } else {
                cli = action3D
            }
            //先清除所有贴图
            for (let i = 0; i < maps.length; i++) {
                await cli.setNormalMap("", maps[i].value, v.color)
            }
            if (v.codes_info && v.codes_info.length !== 0) {
                for (let i = 0; i < v.codes_info.length; i++) {
                    let item = v.codes_info[i]
                    if (item.maps) {
                        for (let j = 0; j < item.maps.length; j++) {
                            let jitem = item.maps[j]
                            await cli.setNormalMap(jitem.url, jitem.map, item.color, item.code)
                        }
                    }
                }
            }
            setCurFabric(v)
        } catch (error) {
            console.log(error)
            Toast.fail("底色加载失败")
        }
    }

    //加载面料
    const loaderFabric = async (attr: any, threeClient: any) => {
        for (let code in attr) {
            if (!code) {
                continue
            }
            let item = attr[code]
            //清除之前的贴图
            threeClient.clearMap(code)
            //加载贴图
            for (let m = 0; m < item.maps.length; m++) {
                let a = item.maps[m]
                if (!a.url) {
                    continue
                }
                await threeClient.setMap(a.url, a.map, code)
            }
            threeClient.setDisplacementMapAttr(code, item.displacementScale, item.displacementBias)
            //设置重复
            threeClient.openRepeat(code, item.repeat)
            if (item.repeat) {
                //设置重复次数
                threeClient.setMapRepeat(code, item.repeatNum, item.repeatNumY)
            } else {
                //设置重复次数
                threeClient.setMapRepeat(code, 1, 1)
            }
            //设置offset
            threeClient.setMapOffset(code, item.offsetX, item.offsetY)
            //设置材质颜色
            threeClient.setMaterialColor(code, item.color)
            //设置透明
            threeClient.setMaterialTransparent(code, item.transparent)
        }
    }

    //加载图案
    const loaderPattern = async (attr: any, fs: any, fabricClient: any, canvasWidth: any) => {
        fabricClient.clearAll()
        for (let i = 0; i < attr.canvas_data.length; i++) {
            let item = attr.canvas_data[i]
            for (let obj = 0; obj < item.data.objects.length; obj++) {
                let s = item.data.objects[obj]
                let float_w = parseFloat(canvasWidth)
                let resource_float_w = 512
                s.left = float_w * parseFloat(s.left) / resource_float_w
                s.top = float_w * parseFloat(s.top) / resource_float_w
                s.scaleX = float_w * parseFloat(s.scaleX) / resource_float_w
                s.scaleY = float_w * parseFloat(s.scaleY) / resource_float_w
                // s.width = 294*s.width/444
                // s.height = 294*s.height/444
                if (s.type.indexOf("text") !== -1) {
                    await fontLoader(s.fontFamily, s.font_family_url)
                }
            }
            fs.get(item.code)?.loadDataFromJson(item.data)
        }
    }

    //加载背景
    const loaderBg = async (attr: any, threeClient: any) => {
        if (attr.cover) {
            await threeClient.setSceneBg(process.env.REACT_APP_OSS_URL + attr.cover)
        } else {
            threeClient.clearSceneBg()
        }
    }

    useEffect(() => {

        //获取oss签名
        http.get(apis.ALI_STS).then((ossdata: any) => {
            const store = new OSS({
                region: process.env.REACT_APP_OSS_REGION,
                bucket: process.env.REACT_APP_OSS_BUCKET,
                accessKeyId: ossdata?.access_key_id,
                accessKeySecret: ossdata?.access_key_secret,
                stsToken: ossdata?.security_token,
                refreshSTSToken: async () => {
                    const info: any = await http.get(apis.ALI_STS);
                    return {
                        accessKeyId: info?.access_key_id,
                        accessKeySecret: info?.access_key_secret,
                        stsToken: info?.security_token
                    }
                },
                refreshSTSTokenInterval: 300000
            });
            setOssClient(store)
        })

        //启动3D场景
        let ops = {
            canvasDom: canvasRef?.current as HTMLCanvasElement,
            canvasWidth: 300,
            canvasHeight: 300
        }
        //初始化3DClient
        let client: Client3D = new Client3D(ops)
        setThreeClient(client)
        threeClientRef.current = client
        //启动3D场景
        client.start()

        //初始化fabricClient
        const fabricClient = new ClientFabric()
        setFabricClient(fabricClient)

        http.get(`${apis.DATA_DETAIL}?hash_id=${params.id}`).then(async (data: any) => {
            data.product_materials = JSON.parse(data.product_materials)
            data.materials = data.product_materials
            data.price = parseInt(data.materials.base_price) * 100
            for (let i = 0; i < data?.product_materials?.cutparts?.length; i++) {
                let item = data?.product_materials?.cutparts[i]
                item.price = parseFloat(item.price)
            }

            setDetailData(data)
            //等待dom加载完成
            await sleep(500)

            try {
                let resource: any = await axios.get(process.env.REACT_APP_OSS_URL + data.path)
                resource = resource.data
                console.log("resource", resource, data)
                const action3D: any = await client.loadModel(process.env.REACT_APP_OSS_URL + resource.model_url, (p: number) => {
                    setProgress(p)
                })
                setAction3D(action3D)

                //初始化所有fabrci句柄和3D句柄

                for (let i = 0; i < data?.product_materials?.cutparts?.length; i++) {
                    let item = data?.product_materials?.cutparts[i]
                    const fclient = fabricClient.loadCanvas(item.code, process.env.REACT_APP_OSS_URL + item.img)
                    fclients.set(item.code, fclient)

                    const tclient = action3D.setMapCanvasTextureByName(item.code, document.getElementById(item.code) as HTMLCanvasElement)
                    threeclients.set(item.code, tclient)

                    //开启所有2D与3D同步监听
                    fclient?.onCanvas((e: any, controPosition: any, event: string) => {
                        if (event === "after:render") {
                            const acObj = fclient.getFabricCanvas().getActiveObject()
                            if (acObj) {
                                setCurActivityObject(acObj)
                            } else {
                                setCurActivityObject(null)
                            }
                            tclient.needsUpdate()
                            setControPosition(controPosition)
                            // console.log("ing")
                        }
                        if (event === "object:modified") {
                            setObjectModified(new Date().getTime())
                        }
                    })
                }

                const menuHeight = menuRef.current?.getHeight!() as number
                setMenuHeight(menuHeight)
                const goldenRatio = document.body.clientHeight * 0.5
                // const w = document.body.clientHeight - goldenRatio - CutpartHeight - safeHeightRef.current.clientHeight - 80
                const w = document.body.clientHeight - goldenRatio - CutpartHeight - safeHeightRef.current.clientHeight - 80

                //设置裁片高度
                fabricClient.setWidth(w)
                setFabricW(w)
                setSafeHeigth(safeHeightRef.current.clientHeight)

                //设置3D的高度
                const canvas3DHeight = document.body.clientHeight - InfoHeight - menuHeight - InfoBottom - HeadHeight - 15
                client.setCanvasSize(document.body.clientWidth, canvas3DHeight)

                //初始化面料
                await loaderFabric(resource?.fabricAttr, client)

                //初始化图案
                await loaderPattern(resource?.canvasAttr, fclients, fabricClient, w)

                //初始化背景
                await loaderBg(resource?.bgAttr, client)

                //设置第一个部位活动
                if (data.product_materials.cutparts && data.product_materials.cutparts.length !== 0) {
                    titleChange(data.product_materials.cutparts[0].code, data)

                    //fabricClient.setBackgroundImage(data.product_materials.cutparts[0].code, "")
                }





            } catch (error) {
                Toast.fail("加载出错")
                console.log(error)
            }




        }).catch(error => {
            console.error(error)
            // Toast.fail(error.message)
        })

        return () => {
            client?.destroy()
        }
        //eslint-disable-next-line
    }, [params.id])

    return (
        <div style={{
            background: "linear-gradient(180deg, rgba(238,238,238,0) 0%, #F8F8F8 100%)",
            height: "100vh"
        }}>
            <div className="a" style={{ display: (detailData?.materials?.map_info && detailData?.materials?.map_info?.length !== 0) ? "block" : "none" }}>
                <Drag direction="y" style={{ right: '0px', top: "20vh", zIndex: "999!important" }}>
                    <FixedNav
                        onChange={fixedNavOnChange}
                        visible={visibleFixedNav}
                        // navList={navList}
                        unActiveText="选择颜色"
                        activeText="收起颜色"
                        slotList={
                            <div className="nut-fixednav__list" slot="list" style={{ width: detailData?.materials?.map_info?.length * 25 || 0, marginRight: 8 }}>
                                {
                                    detailData?.materials?.map_info?.map((v: any) => (
                                        <div onClick={async () => {
                                            Toast.loading("加载中", {
                                                cover: true, // 是否展示透明遮罩层
                                                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                                                closeOnClickOverlay: false, // 点击遮罩可关闭
                                                duration: 0
                                            })
                                            for (let i = 0; i < maps.length; i++) {
                                                threeClient?.clearNormalMap(maps[i].value, v.color)
                                            }
                                            await choiceFabric(v, null, null)
                                            Toast.hide()
                                        }} key={v.color} style={{
                                            width: 20,
                                            height: 20,
                                            background: v.color,
                                            marginTop: 15,
                                            borderRadius: "50%",
                                            border: curFabric.color === v.color ? "solid 1px rgb(245, 119, 102)" : "solid 1px #fff"
                                        }}></div>
                                    ))
                                }
                            </div>
                        }
                    />
                </Drag>
            </div>
            <Context.Provider value={{
                appConfig,
                detailData,
                curCanvasClient,
                curThreeClient,
                controPosition,
                editMode,
                onEditMode,
                fabricW,
                curCutpartCode,
                fabricClient,
                threeClient,
                menuHeight,
                curActivityObject,
                onSubMenuMode,
                subMenuMode,
                showTitle,
                setShowTitle,
                safeHeigth,
                objectModified,
                preview3D,
                curFabric,
                ossClient,
                setCurEvent,
                curEvent,
                titleChange
            }}>
                <Plugins>
                    <div className="displayAnimate" style={previewMode ? {
                        position: "fixed",
                        ...{ zIndex: 12, background: "linear-gradient(180deg, rgba(238,238,238,1) 0%, #F8F8F8 100%)", left: 0, top: 0, bottom: 0, right: 0, margin: "auto" }
                    } : { position: "relative" }}>
                        <canvas className="displayAnimate" ref={canvasRef}></canvas>
                        {
                            previewMode && <Button onClick={quitPreview3D} style={{
                                position: "fixed",
                                bottom: "5%",
                                left: 0,
                                right: 0,
                                margin: "auto",
                                width: 130,
                            }} type="primary">退出预览</Button>
                        }
                    </div>
                    <Menu action={menuRef} />
                    <Loading progress={progress} />
                    <div ref={safeHeightRef} style={{
                        height: "env(safe-area-inset-bottom)",
                        position: "fixed",
                        zIndex: -1,
                        bottom: 0,
                        left: 0
                    }}></div>
                </Plugins>
            </Context.Provider>
        </div>
    )
}

export default Index