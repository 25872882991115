import React, { useState, useImperativeHandle, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import TContext from '../../context'
import PContext from '../context'
import Animate from '../../../animate'
import { Input, TextArea, Tabs, Range, Infiniteloading, Toast } from '@nutui/nutui-react';
import loadFont from '../../../../lib/font'
import http from '../../../../lib/http'
import * as apis from '../../../../lib/apis'
import { cloneDeep } from 'lodash';

const textColor = ["#FFFFFF", "#000000", "#4D4D4D", "#808080", "#B3B3B3", "#E6E6E6", "#3b2722", "#5a3f34", "#9f877d", "#d4cbc4", "#556c78", "#90a0ad", "#ced6da", "#f7e6b0", "#f7f4c3", "#f1f5e6", "#e3f0fb", "#eae6f3", "#f4dae2", "#fad3cb", "#205d62", "#3a9284", "#8cba53", "#4d6d45", "#b7d332", "#a5cea3", "#84c4ba", "#a3bbe1", "#9ca1d0", "#4b9ed8", "#1d5192", "#1e296e", "#3f4fa0", "#432082", "#584096", "#853b92", "#831e4c", "#e60012", "#e24c3d", "#f188ac", "#f69796", "#f38435", "#e4ad35", "#edd481", "#f6f19d", "#f0cb59", "#be8a2f", "#2c2929"]


interface Props {
    action?: any
}

export interface Action {
    setShow?: (isShow: boolean) => void;
    editShow?: () => void; //编辑的显示
    confirm?: () => boolean;
    cancel?: () => void;
}

export const Height = 260

var previewTextObject: any = null
var tempJson: any = null

const Index = (props: Props) => {
    const params: any = useParams()
    const [show, setInnerShow] = useState(false)
    const [currentColor, setCurrentColor] = useState<string>(textColor[1])
    const [fontWeight, setFontWeight] = useState<string>("normal")
    const [fontStyle, setFontStyle] = useState<string>("normal")
    const [fontFamily, setFontFamily] = useState<string>()
    const [fontOpacity, setFontOpacity] = useState<number>()
    const [inputText, setInputText] = useState<string>("")
    const [swiperClient, setSwiperClient] = useState<any>()
    const [paneKey, setPaneKey] = useState<number>()
    const [page, setPage] = useState<number>()
    const [fontList, setFontList] = useState<any>({ list: [], total: 0 })

    const tc = useContext(TContext)
    const pc = useContext(PContext)

    useImperativeHandle(props.action, (): Action => ({
        setShow,
        confirm,
        cancel,
        editShow
    }));

    //加载字体列表
    const loadList = async (page: number) => {
        try {
            const list: any = await http.post(apis.FONT_LIST, {
                page,
                page_size: 10,
                status: 1,
                hash_id: params.id
            })
            if (page === 1) {
                setFontList(list)
                return
            }
            let cpList: any = cloneDeep(fontList)
            cpList.total = list.total
            cpList.list = [...cpList.list, ...list.list]
            setFontList(cpList)
        } catch (error) {

        }
    }

    //加载更多
    const onLoadMore = async (done: () => void) => {
        let cpPage: any = cloneDeep(page)
        cpPage++
        setPage(cpPage)
        try {
            await loadList(cpPage)
        } catch (error) {
            let cpPage: any = cloneDeep(page)
            cpPage--
            setPage(cpPage)
        }
        done()
    }

    const editShow = () => {
        if (previewTextObject.type !== "text") {
            return
        }
        previewTextObject = tc.curActivityObject
        tempJson = tc?.fabricClient?.getJsonData()
        setPaneKey(0)
        setInnerShow(true)
        //初始字体
        setFontFamily(previewTextObject.fontFamily)
        //初始文字
        setInputText(previewTextObject.text)
        //设置颜色
        setCurrentColor(previewTextObject.fill)
        let colorIndex = textColor.findIndex(v => v === previewTextObject.fill)
        if (colorIndex === -1) {
            colorIndex = 0
        }
        swiperClient?.slideTo(colorIndex)
        //设置默认weight
        setFontWeight(previewTextObject.fontWeight || "normal")
        //设置默认斜体
        setFontStyle(previewTextObject.fontStyle || "normal")
        //设置默认字体透明度
        setFontOpacity(previewTextObject.opacity || 1)
    }

    const setShow = (isShow: boolean) => {
        setInnerShow(isShow)
        if (isShow) {
            tempJson = tc?.fabricClient?.getJsonData()
            const defaultTxt = ""
            const defaultColor = textColor[1]
            setPaneKey(0)
            setFontFamily("font0")
            //初始空文字
            setInputText(defaultTxt)
            //显示之后就预先添加一个空文本对象
            previewTextObject = tc?.curCanvasClient?.addText(defaultTxt)
            //设置默认颜色
            setCurrentColor(defaultColor)
            swiperClient?.slideTo(textColor.findIndex(v => v === defaultColor))
            previewTextObject.set({ fill: defaultColor })
            //设置默认weight
            setFontWeight("normal")
            //设置默认斜体
            setFontStyle("normal")
            //设置默认字体透明度
            setFontOpacity(1)
        }
    }


    // //取消
    const cancel = () => {
        previewTextObject = null
        setInnerShow(false)
    }

    //确认
    const confirm = () => {
        if (!previewTextObject) {
            return false
        }
        if (!previewTextObject.text.trim()) {
            return false
        }
        pc?.addStep!(tempJson)
        tempJson = null
        setInnerShow(false)
        tc.curCanvasClient.getFabricCanvas().setActiveObject(previewTextObject)
        tc.curCanvasClient.getFabricCanvas().renderAll()
        previewTextObject = null
        return true
    }

    //选择颜色
    const choiceColor = (color: string) => {
        setCurrentColor(color)
        previewTextObject.set({ fill: color })
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    //监听文字输入变化
    const inputOnchange = (val: any) => {
        setInputText(val)
        previewTextObject.text = val
        tc.curCanvasClient.getFabricCanvas().viewportCenterObject(previewTextObject)
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    const inputOnBlur = (e: any) => {
        let val = e.target.value
        setInputText(val)
        previewTextObject.text = val
        tc.curCanvasClient.getFabricCanvas().viewportCenterObject(previewTextObject)
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    //选择字体粗度
    const choiceFontWeight = (e: any) => {
        e.preventDefault()
        if (fontWeight === "normal") {
            setFontWeight("bold")
            previewTextObject.fontWeight = "bold"
        } else {
            setFontWeight("normal")
            previewTextObject.fontWeight = "normal"
        }
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    //选择字体斜
    const choiceFontStyle = (e: any) => {
        e.preventDefault()
        if (fontStyle === "normal") {
            setFontStyle("italic")
            previewTextObject.fontStyle = "italic"
        } else {
            setFontStyle("normal")
            previewTextObject.fontStyle = "normal"
        }
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    //字体透明度变化
    const fontOpacityChange = (val: number) => {
        setFontOpacity(val)
        previewTextObject.opacity = val
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    //tabs切换
    const tabOnChange = async ({ paneKey }: any) => {
        setPaneKey(paneKey)
        if (paneKey === 1) { //加载字体
            Toast.loading("加载中", {
                cover: true, // 是否展示透明遮罩层
                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                closeOnClickOverlay: false, // 点击遮罩可关闭
                duration: 0
            })
            await loadList(1)
            setPage(1)
            Toast.hide()
        }
    }

    //选择字体
    const choiceFontFamily = async (v: any) => {
        setFontFamily(`font${v.id}`)
        if (v.id === 0) {
            previewTextObject.fontFamily = "Times New Roman"
            setFontFamily(`font0`)
            tc.curCanvasClient.getFabricCanvas().renderAll()
            return
        }
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        try {
            await loadFont(`font${v.id}`, process.env.REACT_APP_OSS_URL + v.path)
            Toast.hide()
        } catch (error) {
            Toast.fail("字体加载失败")
        }
        previewTextObject.fontFamily = `font${v.id}`
        previewTextObject.set("font_family_url", process.env.REACT_APP_OSS_URL + v.path)
        tc.curCanvasClient.getFabricCanvas().renderAll()
    }

    return (
        <Animate show={show} style={{
            bottom: 0,
            position: "fixed",
            left: 0,
            zIndex: 11,
            width: "100%",
            background: "#FFFFFF",
            height: Height,
            paddingBottom: tc.safeHeigth
        }}>
            <div>
                <TextArea style={{ height: 30 }} defaultValue={inputText} onBlur={inputOnBlur} onChange={inputOnchange} placeholder="点击输入文字或字母" />
            </div>
            <div>
                <Tabs background="#FFFFFF" value={paneKey} onChange={tabOnChange}>
                    <Tabs.TabPane title="样式">
                        <div style={{
                            height: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly"
                        }}>
                            <Swiper
                                onSwiper={s => setSwiperClient(s)}
                                slidesPerView={"auto"}
                                style={{
                                    width: "96%"
                                }}
                            >
                                {
                                    textColor?.map((v: any, k: number) => (
                                        <SwiperSlide onClick={(e) => {
                                            e.preventDefault()
                                            choiceColor(v)
                                        }} style={{
                                            width: 'auto'
                                        }} key={v}>
                                            <div style={{
                                                width: 20,
                                                height: 20,
                                                marginRight: k === textColor.length - 1 ? 0 : 12,
                                                borderRadius: "50%",
                                                background: v,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: `1px solid ${textColor[textColor.length - k - 1]}`
                                            }}>
                                                <div style={{
                                                    width: "60%",
                                                    height: "60%",
                                                    borderRadius: "50%",
                                                    border: currentColor === v ? `1px solid ${textColor[textColor.length - k - 1]}` : "none"
                                                }}></div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 24
                        }}>
                            <span style={{
                                fontSize: 12,
                                flexShrink: 0,
                                marginRight: 10
                            }}>透明度</span>
                            <Range hiddenRange hiddenTag modelValue={fontOpacity} max={1} min={0} step={0.1} onChange={fontOpacityChange} />
                        </div>
                        <div style={{
                            marginTop: 24
                        }}>
                            <div onClick={choiceFontWeight} style={{
                                width: 50,
                                height: 50,
                                border: fontWeight === "bold" ? "2px solid #000" : "2px solid #666",
                                color: fontWeight === "bold" ? "#000" : "#666",
                                borderRadius: 5,
                                fontSize: 14,
                                textAlign: "center",
                                verticalAlign: "middle",
                                display: "table-cell"
                            }}>
                                <div>B</div>
                                <div>粗体</div>
                            </div>
                            <div style={{
                                display: "table-cell",
                                width: 10
                            }}></div>
                            <div onClick={choiceFontStyle} style={{
                                width: 50,
                                height: 50,
                                border: fontStyle === "italic" ? "2px solid #000" : "2px solid #666",
                                color: fontStyle === "italic" ? "#000" : "#666",
                                borderRadius: 5,
                                fontSize: 14,
                                textAlign: "center",
                                verticalAlign: "middle",
                                display: "table-cell"
                            }}>
                                <div>I</div>
                                <div>斜体</div>
                            </div>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane title="字体">
                        <div id="fontScroll" style={{
                            height: Height * 0.5,
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}>
                            <Infiniteloading
                                loadIcon=""
                                containerId="fontScroll"
                                useWindow={false}
                                hasMore={fontList?.total !== fontList?.list?.length}
                                onLoadMore={onLoadMore}
                            >
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3,auto)"
                                }}>
                                    {
                                        [{ id: 0, name: "默认" }, ...fontList?.list].map((v: any) => (
                                            <div onClick={() => choiceFontFamily(v)} style={{
                                                width: "28vw",
                                                marginBottom: "1vw",
                                                height: "15vw",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: 6,
                                                border: fontFamily === `font${v.id}` ? "1px solid #b0cd76" : "1px solid rgb(238, 238, 238)",
                                                background: v.cover ? `url("${process.env.REACT_APP_OSS_URL + v.cover + "?x-oss-process=image/resize,w_300"}")` : "#eee",
                                                backgroundSize: "100% 100%",
                                                backgroundPosition: "center center"
                                            }} key={v.id}>{v.cover ? "" : v.name}</div>
                                        ))
                                    }
                                </div>
                            </Infiniteloading>
                        </div>

                    </Tabs.TabPane>
                </Tabs>
            </div>


        </Animate>

    )

}

export default Index