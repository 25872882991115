import React, { useState, useImperativeHandle, useContext, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import TContext from '../../context'
import PContext from '../context'
import Animate from '../../../animate'
import { Input, TextArea, Tabs, Range, Infiniteloading, Toast } from '@nutui/nutui-react';
import loadFont from '../../../../lib/font'
import http from '../../../../lib/http'
import * as apis from '../../../../lib/apis'
import { cloneDeep } from 'lodash';
import md5 from 'md5'


interface Props {
    action?: any
}

export interface Action {
    openFile?: () => void;
}

function optimizeImage(file: any, maxSizeInBytes: any, callback: any) {
    // 检查文件大小是否超过指定的最大大小
    if (file.size <= maxSizeInBytes) {
        // 如果文件小于或等于最大大小，则不需要优化，直接调用回调函数
        callback(file);
        return;
    }

    // 创建一个用于加载图像的 FileReader 对象
    var reader = new FileReader();

    // 当 FileReader 完成加载时执行此回调函数
    reader.onload = function (e: any) {
        var img: any = new Image();
        img.onload = function () {
            var canvas = document.createElement('canvas');
            var ctx: any = canvas.getContext('2d');

            // 计算缩放比例，将图像大小限制在最大大小以下
            var scaleFactor = Math.min(1, Math.sqrt(maxSizeInBytes / file.size));

            // 设置画布尺寸
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;

            // 在画布上绘制图像
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // 将画布上的图像转换为 Blob 对象
            canvas.toBlob(function (blob: any) {
                // 创建一个新的文件对象，以便将其传递给回调函数
                var optimizedFile = new File([blob], file.name, { type: file.type });

                // 调用回调函数，并传递优化后的文件对象
                callback(optimizedFile);
            }, file.type);
        };

        img.src = e.target.result;
    };

    // 读取文件数据
    reader.readAsDataURL(file);
}


const Index = (props: Props) => {
    const ref = useRef<any>()
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    const openFile = () => {
        ref.current.click()
    }

    const fileChoice = async (e: any) => {
        if (e.target.files && e.target.files.length !== 0) {
            let file = e.target.files[0]

            var maxSize = 2 * 1024 * 1024; // 3MB

            Toast.loading("上传中", {
                cover: true, // 是否展示透明遮罩层
                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                closeOnClickOverlay: false, // 点击遮罩可关闭
                duration: 0
            })

            optimizeImage(file, maxSize, async (optimizedFile: any) => {
                let ss = file.name.split(".")
                if (ss.length === 0) {
                    Toast.fail("文件格式不正确")
                    return
                }
                //上传效果图
                const uuid = await http.get(apis.UUID)
                const filename = `files/${md5(uuid)}.${ss[ss.length - 1]}`;
                const ossData = await tc?.ossClient?.put(filename, optimizedFile)
                if (!ossData.name) {
                    Toast.fail("上传失败")
                    return
                }
                ref.current.value = ''

                await tc.curCanvasClient.addImageById(process.env.REACT_APP_OSS_URL + ossData.name, tc.fabricW as number * 0.5)
                tc.curCanvasClient.getFabricCanvas().renderAll()
                Toast.hide()
                pc.openCutpart!()
            });




        }


    }

    useImperativeHandle(props.action, (): Action => ({
        openFile,
    }));

    return (
        <Animate show={false}>
            <input accept="image/*" type='file' onChange={fileChoice} ref={ref} />
        </Animate>

    )

}

export default Index