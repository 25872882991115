import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import http from '../../lib/http'
import * as apis from '../../lib/apis'



const Index = () => {
    const params: any = useParams()

    useEffect(() => {
        //获取配置
        http.get(apis.CONFIG + "?hash_id=" + params.id).then((cdata: any) => {
            try {
                cdata.redirect_conf = JSON.parse(cdata.redirect_conf)
                let w: any = window as any
                w.wx.miniProgram.navigateTo({ url: `${cdata.redirect_conf.miniapp}?order_no=${params.order_no}` })
                console.log("跳转到：", `${cdata.redirect_conf.miniapp}?${params.order_no}`)
            } catch (error) {

            }
        })
    }, [params.id])

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            height: "100vh"
        }}>
            <h3>提交成功，正在跳转...</h3>
        </div>
    )
}

export default Index