export const DATA_DETAIL = `${process.env.REACT_APP_API_HOST}/client/open/design/detail`
export const PATTERN_CATEGORY_LIST = `${process.env.REACT_APP_API_HOST}/client/open/sdk/pattern/category/list`
export const PATTERN_LIST = `${process.env.REACT_APP_API_HOST}/client/open/sdk/pattern/list`
export const FONT_LIST = `${process.env.REACT_APP_API_HOST}/client/open/sdk/font/list`
export const ALI_STS = `${process.env.REACT_APP_API_HOST}/client/open/sts/token`
export const ORDER_CREATE = `${process.env.REACT_APP_API_HOST}/client/open/sdk/order/create`

export const CONFIG = `${process.env.REACT_APP_API_HOST}/client/open/sdk/config/data`

export const IMAGE_SPLIT = `${process.env.REACT_APP_API_HOST}/client/open/sdk/image/split`
export const IMAGE_STYLE = `${process.env.REACT_APP_API_HOST}/client/open/sdk/image/style`


export const UUID = `${process.env.REACT_APP_API_HOST}/uuid`





