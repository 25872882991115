import YesIcon from '../../../../static/img/yes.png'
import KouTuIcon from '../../../../static/img/Info_chart.png'
import CaijianIcon from '../../../../static/img/caijian.png'
import LvjingIcon from '../../../../static/img/滤镜.png'
import FenggeIcon from '../../../../static/img/Artboards_Diversity_Avatars_by_Netguru-16.png'
import LightIcon from '../../../../static/img/亮度.png'
import DuibiDuIcon from '../../../../static/img/对比度.png'
import BaoheDuIcon from '../../../../static/img/saturation-饱和度.png'
import DongmanIcon from '../../../../static/img/频道-动漫.png'
import D3Icon from '../../../../static/img/3D_01.png'
import ShouHuiIcon from '../../../../static/img/手绘.png'
import QianBiIcon from '../../../../static/img/铅笔.png'
import TexiaoIcon from '../../../../static/img/特效.png'
import BoliIcon from '../../../../static/img/玻璃.png'
import SmIcon from '../../../../static/img/水墨.png'



import DeleteIcon from '../../../../static/img/delete.png'
import React, { useState, useImperativeHandle, useContext, useEffect, useRef } from 'react';
import TContext from '../../context'
import PContext from '../context'
import Animate from '../../../animate'
import http from '../../../../lib/http'
import * as apis from '../../../../lib/apis'
import { Button, Range, Dialog, Popup, Toast } from '@nutui/nutui-react';
import Cropper from 'cropperjs';
import md5 from 'md5'
let cropper: any


export enum Events {
    NONE = 0, //无事件
    QUEREN_SUCAI = 1,
    EDIT_PATTERN = 2,
    CONFIRM_GRAFFITI = 3, //确认涂鸦
    CONFIRM_TEXT = 4, //确认文字
    CONFIRM_TEXT_PATTERN = 5, //确认文字纹样
    CONFIRM_FASHION = 6 //确认款式
}



export interface Action {
    setShow?: (s: boolean) => void; //显示
    setTitle?: (s: string) => void; //设置title文字
    registEvent?: (s: Events) => void; //注册事件
    setBottom?: (s: number) => void; //设置bottom
}

export interface Props {
    action?: any
}

export const Height = 30


const Index = (props: Props) => {
    const [show, setShow] = useState<boolean>(false)
    const [attrEdit, setAttrEdit] = useState<number>(0)
    const [bottom, setBottom] = useState<number>(0)
    const [cropImgVisible, setCropImgVisible] = useState<boolean>(false)
    const [title, setTitle] = useState<string>("编辑纹样")
    const [event, setEvent] = useState<Events>()
    const [cropImgSrc, setCropImgSrc] = useState<string>()
    const tc = useContext(TContext)
    const pc = useContext(PContext)
    const cropImg = useRef<any>()

    const registEvent = (s: Events) => {
        tc?.setCurEvent!(s) //通知到全局
        setEvent(s)
    }

    useImperativeHandle(props.action, () => ({
        setShow,
        setTitle,
        registEvent,
        setBottom
    }));

    useEffect(() => {
        if (!tc?.curActivityObject) {
            setAttrEdit(0)
        }
    }, [tc?.curActivityObject])


    const doCrop = () => {
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        cropper.getCroppedCanvas().toBlob(async (blob: any) => {
            const filename = "files/" + md5(new Date().getTime()) + ".png";
            await tc.ossClient.put(filename, blob);
            // cropper.destroy()
            tc.curCanvasClient.removeObject(tc.curActivityObject)
            await tc.curCanvasClient.addImageById(process.env.REACT_APP_OSS_URL + filename, tc.fabricW as number * 0.5)
            tc.curCanvasClient.getFabricCanvas().renderAll()
            Toast.hide()
            setCropImgVisible(false)
            cropper.destroy()
        })
    }

    const doStyle = async (style: string) => {
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        try {
            let path: any = await http.post(apis.IMAGE_STYLE, {
                url: tc.curActivityObject._originalElement.src + "?x-oss-process=image/resize,l_1999",
                style
            })
            tc.curCanvasClient.removeObject(tc.curActivityObject)
            await tc.curCanvasClient.addImageById(process.env.REACT_APP_OSS_URL + path, tc.fabricW as number * 0.5)
            tc.curCanvasClient.getFabricCanvas().renderAll()
        } catch (error) {

        }
        Toast.hide()
    }


    //确认
    const confirm = (e: any) => {
        e.preventDefault();
        pc?.onSubComplete!(event as Events)
    }

    //取消
    const cancel = (e: any) => {
        e.preventDefault();
        pc?.onSubCancel!(event as Events)
    }

    //调整亮度
    const slideAttr = async (type: number) => {
        if (type === 2) {
            Toast.loading("加载中", {
                cover: true, // 是否展示透明遮罩层
                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                closeOnClickOverlay: false, // 点击遮罩可关闭
                duration: 0
            })
            if (cropper) {
                cropper.destroy()
            }
            setCropImgSrc(tc.curActivityObject._originalElement.src)
            setTimeout(() => {
                cropper = new Cropper(cropImg.current, {
                    // 宽高比
                    // aspectRatio: 16 / 9,
                    rotatable: true,
                    scalable: true,
                    crop(event) {

                    }
                });
                setCropImgVisible(true)
                Toast.hide()
            }, 500)
        } else if (type === 1) {
            Toast.loading("加载中", {
                cover: true, // 是否展示透明遮罩层
                // coverColor: 'rgba(0, 0, 0, 0)', // 遮罩颜色设定
                closeOnClickOverlay: false, // 点击遮罩可关闭
                duration: 0
            })
            try {
                let path: any = await http.post(apis.IMAGE_SPLIT, {
                    url: tc.curActivityObject._originalElement.src + "?x-oss-process=image/resize,l_1999"
                })
                tc.curCanvasClient.removeObject(tc.curActivityObject)
                await tc.curCanvasClient.addImageById(process.env.REACT_APP_OSS_URL + path, tc.fabricW as number * 0.5)
                tc.curCanvasClient.getFabricCanvas().renderAll()
            } catch (error) {
                Toast.fail(`文件太大`)
            }
            Toast.hide()
        } else {
            setAttrEdit(type)
        }

    }

    return (
        <Animate show={show} showType="flex" style={{
            height: Height,
            background: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            position: "fixed",
            left: 0,
            display: "flex",
            width: "100%",
            bottom,
            // boxShadow: "rgb(0 0 0 / 10%) 0px -1px 5px 0px",
            borderRadius: "12px 12px 0px 0px",
            zIndex: 17
        }}>
            <div style={{
                display: cropImgVisible ? 'block' : 'none',
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: "auto",
                background: "rgba(0,0,0,0.3)",
                zIndex: 998
            }}>
                <div style={{
                    height: "50vh",
                    position: "fixed",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                    zIndex: 999
                }}>
                    <img style={{ display: "block", width: 300, height: 300 }} src={cropImgSrc} ref={cropImg} />
                    <div style={{ height: 12 }}></div>
                    <div style={{ width: 120, display: "flex", justifyContent: "space-between", margin: "0 auto" }}>
                        <Button size="small" shape="square" type="primary" onClick={doCrop}>确定</Button>
                        <Button size="small" shape="square" type="primary" onClick={() => setCropImgVisible(false)}>取消</Button>
                    </div>
                </div>
            </div>
            <div style={{
                padding: "0 5px",
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: "100%"
            }}>
                <div style={{
                    width: Height,
                    height: Height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} onClick={cancel}>
                    <img style={{
                        width: 13,
                        height: 13
                    }} src={DeleteIcon} alt="" />
                </div>
                <div style={{
                    fontSize: 14,
                    fontWeight: 500
                }}>
                    {
                        (tc?.curActivityObject && title === '编辑纹样' && !attrEdit) && <div style={{
                            display: "flex",
                            alignItems: "center",
                            width: "calc(100vw - 60px)",
                            justifyContent: "space-evenly"
                        }}>
                            <span onClick={() => { slideAttr(1) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>抠图</span>
                            <span onClick={() => { slideAttr(2) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>裁剪</span>
                            <span onClick={() => { slideAttr(3) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>滤镜</span>
                            <span onClick={() => { slideAttr(4) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>风格化</span>
                            <span onClick={() => { slideAttr(5) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>亮度</span>
                            <span onClick={() => { slideAttr(6) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>对比度</span>
                            <span onClick={() => { slideAttr(7) }} style={{
                                display: "inline-block",
                                fontSize: 12,
                                border: "solid 1px #333",
                                padding: "1px 4px",
                                cursor: "pointer"
                            }}>饱和度</span>
                        </div>
                    }
                    <div style={{
                        width: "300px",
                        display: (attrEdit === 7 && tc?.curActivityObject) ? 'block' : 'none'
                        // overflow: "auto"
                    }}>
                        <Range min={-50} max={50} defaultValue={0} style={{ width: "100px" }} onChange={val => {
                            tc.curCanvasClient.slideBh(tc.curActivityObject, val / 100)
                        }} />
                    </div>
                    <div style={{
                        width: "300px",
                        display: (attrEdit === 5 && tc?.curActivityObject) ? 'block' : 'none'
                        // overflow: "auto"
                    }}>
                        <Range min={-50} max={50} defaultValue={0} style={{ width: "100px" }} onChange={val => {
                            tc.curCanvasClient.slideLd(tc.curActivityObject, val / 100)
                        }} />
                    </div>
                    <div style={{
                        width: "300px",
                        display: (attrEdit === 6 && tc?.curActivityObject) ? 'block' : 'none'
                        // overflow: "auto"
                    }}>
                        <Range min={-50} max={50} defaultValue={0} style={{ width: "100px" }} onChange={val => {
                            tc.curCanvasClient.slideDbd(tc.curActivityObject, val / 100)
                        }} />
                    </div>
                    <div style={{
                        display: (attrEdit === 4 && tc?.curActivityObject) ? 'flex' : 'none',
                        alignItems: "center",
                        width: "calc(100vw - 60px)",
                        justifyContent: "space-evenly"
                    }}>
                        <img src={DongmanIcon} onClick={() => doStyle('anime')} style={{ width: 30, height: 20 }} alt='' />
                        <img src={D3Icon} onClick={() => doStyle('3d')} style={{ width: 30, height: 20 }} alt='' />
                        <img src={ShouHuiIcon} onClick={() => doStyle('handdrawn')} style={{ width: 20, height: 20 }} alt='' />
                        <img src={QianBiIcon} onClick={() => doStyle('sketch')} style={{ width: 20, height: 20 }} alt='' />
                        <img src={TexiaoIcon} onClick={() => doStyle('artstyle')} style={{ width: 20, height: 20 }} alt='' />
                    </div>
                    <div style={{
                        display: (attrEdit === 3 && tc?.curActivityObject) ? 'flex' : 'none',
                        alignItems: "center",
                        width: "calc(100vw - 60px)",
                        justifyContent: "space-evenly"
                    }}>
                        <span onClick={() => {
                            tc.curCanvasClient.blurLvj(tc.curActivityObject)
                        }} style={{
                            display: "inline-block",
                            fontSize: 12,
                            border: "solid 1px #333",
                            padding: "1px 4px",
                            cursor: "pointer"
                        }}>模糊</span>
                        <span onClick={() => {
                            tc.curCanvasClient.grayscale(tc.curActivityObject)
                        }} style={{
                            display: "inline-block",
                            fontSize: 12,
                            border: "solid 1px #333",
                            padding: "1px 4px",
                            cursor: "pointer"
                        }}>灰度</span>
                    </div>
                    {
                        (!tc?.curActivityObject) && <span>{title}</span>
                    }
                </div>
                <div style={{
                    width: Height,
                    height: Height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} onClick={confirm}>
                    <img style={{
                        width: 18,
                        height: 14
                    }} src={YesIcon} alt="" />
                </div>
            </div>
        </Animate>

    )
}

export default Index