import { cloneDeep } from 'lodash'
import React, { useContext, useState, useImperativeHandle } from 'react'
import D3Icon from '../../../../static/img/3d_0.png'
import BackupIcon from '../../../../static/img/backup.png'
import ForwardIcon from '../../../../static/img/forward.png'
import RemoveIcon from '../../../../static/img/remove.png'
import TContext from '../../context'
import PContext from '../context'
import { Dialog, Toast } from '@nutui/nutui-react';


export const Height = 50

const iconBox: React.CSSProperties = {
    backgroundColor: "#f9f9f9",
    width: 34,
    height: 34,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
    flexShrink: 0
}

const iconBoxImg: React.CSSProperties = {
    width: "85%",
    height: "85%",
    display: "block",
    borderRadius: "50%"
}

const orderBtn: React.CSSProperties = {
    color: "#FFFFFF",
    backgroundColor: "#F57766",
    fontSize: 14,
    fontWeight: 500,
    height: 30,
    padding: "0 18px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center"
}

export interface Action {
    addStep?: (j: any) => void;
}

export interface Props {
    action?: any
}


const Index = (props: Props) => {
    const [step, setStep] = useState<number>(-1)
    const tc = useContext(TContext)
    const pc = useContext(PContext)

    const addStep = (j = null) => {
        setStep(tc.fabricClient.addEditData(j) - 1)
    }

    useImperativeHandle(props.action, (): Action => ({
        addStep
    }));

    const backDataDisabled = () => {
        return (step === -1 || step === 0)
    }

    const forwardDataDisabled = () => {
        return ((step + 1) === tc.fabricClient?.getEditData()?.length)
    }

    const backEditData = async (e: any) => {
        e.preventDefault()
        if (backDataDisabled()) {
            return
        }
        let cloneStep: any = cloneDeep(step)
        cloneStep--
        setStep(cloneStep)
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        await tc.fabricClient.recoverEditData(cloneStep)
        pc.checkNotEmptyCodes!()
        Toast.success("操作成功")
        Toast.hide()
    }

    const forwardEditData = async (e: any) => {
        e.preventDefault()
        if (forwardDataDisabled()) {
            return
        }
        let cloneStep: any = cloneDeep(step)
        cloneStep++
        setStep(cloneStep)
        Toast.loading("加载中", {
            cover: true, // 是否展示透明遮罩层
            closeOnClickOverlay: false, // 点击遮罩可关闭
            duration: 0
        })
        await tc.fabricClient.recoverEditData(cloneStep)
        pc.checkNotEmptyCodes!()
        Toast.success("操作成功")
        Toast.hide()
    }

    //清除所有纹样
    const clearAllObject = (e: any) => {
        e.preventDefault()
        if (pc.notEmptyCodes?.length === 0) {
            return
        }
        Dialog.alert({
            title: '清空提醒',
            content: `目前已编辑${pc.notEmptyCodes?.length}个部位，确认删除该方案吗？`,
            onOk: () => {
                tc.fabricClient.clearAll()
                pc.checkNotEmptyCodes!()
                tc.fabricClient.clearEditData()
                setStep(-1)
                Toast.success("操作成功")
            }
        });
    }

    //下单
    const readyDoOrder = () => {
        if (tc?.curEvent) { //如果当前有事件未完成，不可提交订单
            Toast.warn("请先完成当前的编辑")
            return
        }
        pc?.doOrder!()
    }

    //监听活动对象变动
    // useEffect(() => {
    //     setClearDisabled(tc?.fabricClient?.isEmptyAll())
    // }, [tc.curActivityObject])

    const preview3D = () => {
        tc.preview3D!()
        // 设置mediarecoder的参数，这里的codec为H264
        // var track = {
        //     type: 'video',
        //     kind: 'video',
        //     mimeType: 'video/webm;codecs=h264',
        //     initSegment: null
        // };

        // // 创建一个mediarecorder对象
        // var mediaRecorder = new MediaRecorder(tc.threeClient.getCanvasDom().captureStream(), track);

        // // 事件录制开始
        // mediaRecorder.ondataavailable = function (e) {
        //     if (e.data && e.data.size > 0) {
        //         // 将blob对象保存到文件中
        //         var a = document.createElement('a');
        //         document.body.appendChild(a);
        //         a.href = window.URL.createObjectURL(e.data);
        //         a.download = 'svideo.webm';
        //         a.click();
        //         window.URL.revokeObjectURL(a.href);
        //     };
        // };

        // //调用start函数开始录制
        // mediaRecorder.start();

        // //5s之后停止录制
        // setTimeout(function () {
        //     mediaRecorder.stop();
        // }, 5000);
    }

    return (
        <div style={{
            height: Height,
            display: "flex",
            alignItems: "center"
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 20px",
                margin: "0 auto",
                width: "100%"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div onClick={preview3D} style={iconBox}>
                        <img src={D3Icon} style={iconBoxImg} alt="" />
                    </div>
                    <div onClick={backEditData} style={{ ...iconBox, opacity: backDataDisabled() ? 0.5 : 1 }}>
                        <img src={BackupIcon} style={iconBoxImg} alt="" />
                    </div>
                    <div onClick={forwardEditData} style={{ ...iconBox, opacity: forwardDataDisabled() ? 0.5 : 1 }}>
                        <img src={ForwardIcon} style={iconBoxImg} alt="" />
                    </div>
                    <div onClick={clearAllObject} style={{ ...iconBox, opacity: pc.notEmptyCodes?.length === 0 ? 0.5 : 1 }}>
                        <img src={RemoveIcon} style={iconBoxImg} alt="" />
                    </div>
                </div>
                <div style={orderBtn} onClick={readyDoOrder}>下单</div>
            </div>
        </div>
    )
}

export default Index