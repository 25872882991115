import { useEffect, useState } from 'react';
import { Button, Toast } from '@nutui/nutui-react';

const Index = () => {
    const [d, setD] = useState<any>()
    const [fashionId, setFashionId] = useState()

    return (
        <div style={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `url(${process.env.REACT_APP_OSS_URL + d?.config?.bg_url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "90%",
            backgroundPosition: "bottom center",
            backgroundColor: d?.config?.bg_color
        }}>
            <h3 style={{ textAlign: "center" }}>欢迎使用猫耳3D插件</h3>
        </div>
    )
}

export default Index